import React, { useEffect, useMemo, useRef, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import logo from './logo.svg';
import onelogo from './img/one-logo.svg';
import content_img from './img/content.png';
import footer_img from './img/img_8.png';
import "./App.css"
import { FaFacebookF, FaInstagram, FaShareAlt, FaLink } from 'react-icons/fa';
import lang from './language/lang.json'
import _ from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RWebShare } from "react-web-share";

function App() {
  const [language, setLanguage] = useState('en')
  const LinktoDonate = () => {
    return <button
      type="button"
      className="btn btn-primary"
      onClick={() => window.open(`https://my.israelgives.org/${language === 'ru' ? 'en' : language}/fundme/Reim`, window.innerWidth < 768 ? "_self" : '_blank')}>
      {_.get(translate, 'donate')}
    </button>
  }
  function copyUrl() {
    const copyText = window.location.href;
    navigator.clipboard.writeText(copyText);
  }
  const translate = useMemo(() => _.get(lang,language), [language])
  const inputRef = useRef<HTMLButtonElement |  null>(null);
  useEffect(() => {
    !_.isNil(inputRef.current) &&  inputRef.current?.click()
  },[])

  return (
    <div className={`container-fluid g-0`}>
      {/*** NAVBAR */}
      <div className={`container-fluid g-0 gallery-wrap`}>
      <div className='shadow sticky-top'>
        <header className="navbar flex-row container navbar navbar-expand-lg bd-navbar ">
          <nav className="container-xxl bd-gutter flex-wrap flex-lg-nowrap me-xs-5" aria-label="Main navigation">
            <div className="mb-md-0">
              <a href="/" className="e">
                <img src={logo} className="App-logo" alt="logo" />
              </a>
            </div>
            <div>
              <select className="form-select border-0" value={language} onChange={(e) => setLanguage(e.target.value)} >
                <option value="en" >EN</option>
                <option value="ru" >РУ</option>
                <option value="he" selected>עב</option>
              </select>
            </div>
          </nav>
        </header>
      </div>
      {/*** TOP SECTION */}
      {/*** SOCIAL ICONS */}
      <div className={`container mt-sm-5`}>
        <div className='gallery d-flex flex-column-reverse flex-sm-row'>
          <div className='actions d-flex flex-row flex-sm-column'>
            <div className='social-icon'>
              <a href='https://www.facebook.com/Reim70?mibextid=ZbWKwL'
                target={window.innerWidth < 768 ? "_self" : '_blank'}>
                <FaFacebookF />
              </a>
            </div>
            <div className='social-icon'>
              <a href='https://www.instagram.com/explore/locations/745286441/?igshid=MTc4MmM1YmI2Ng==' 
                target={window.innerWidth < 768 ? "_self" : '_blank'}>
                <FaInstagram/>
              </a>
            </div>
            <RWebShare
              data={{
                text: _.get(translate,'carousel.sub-title') +' '+ _.get(translate,'carousel.title'),
                url: window.location.href,
              }}
              onClick={() => console.log("shared successfully!")}
            >
            <div className='social-icon'><FaShareAlt /></div></RWebShare>
            <div className='social-icon'><CopyToClipboard text={window.location.href}><a className='link'><FaLink /></a></CopyToClipboard></div>
          </div>
          {/*** CAROUSEL SECTION */}
          <div id="carouselExampleIndicators" data-bs-ride="carousel"  className={`carousel slide carousel-fade w-100 ${_.isEqual(language,'he') ? 'rtl' : ''}`}>
            <div className="carousel-indicators">
            {_.range(6).map((n,i) => {
              return <button 
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={i}
                aria-current="true"
                aria-label={`Slide ${i + 1}`}
                className={i < 1 ? 'active' : ''}
                ref={i < 1 && inputRef || null}
              >
              </button>
            })}
            </div>
            {/*** CAROUSEL TEXT */}
            <div className="carousel-inner">
              <div data-bs-interval="500" className='gallery-text-container p-4 p-sm-5 d-flex flex-column justify-content-between'>
                <div className='gallery-text'>
                  <div className='sub-title'>
                    {_.get(translate, 'carousel.sub-title')}
                  </div>
                  <div className='title'>
                    {_.get(translate, 'carousel.title')}
                  </div>
                  <div className='caption'>
                    {_.get(translate, 'carousel.caption')}
                  </div>
                </div>
                <LinktoDonate />
              </div>
              {_.range(6).map((n,i) =>
              <div 
                key={i} 
                className={`carousel-item ${i < 1 ? 'active' : ''} img_${i + 1}`} 
                data-bs-interval="2500" 
                style={{
                  backgroundImage: `url(${require(`./img/image_${i + 1}.jpeg`)})`,
                }}>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
      
      {/*** CONTENT SECTION */}
      <div className={`content-section-wrap g-0 `}>
        <div className={`container content-section d-flex flex-column gap-5 ${_.isEqual(language,'he') ? 'rtl' : ''}`}>
          <div>
            <div className='title'>
            {_.get(translate, 'content.title')}
            </div>
            <div>
            {_.get(translate, 'content.body')}
            </div>
          </div>
          <div className='d-xs-flex gap-5'>
            <div className='col-sm-6 content-image'>
            </div>
            <div className=''>
              <div className=''>
                {_.get(translate, 'quot')}
              </div>
            </div>
          </div>
          <LinktoDonate />
        </div>
      </div>
      <div className='container footer-section g-0 position-relative'>
      <div className={`footer-links-section-wrap position-absolute ${_.isEqual(language,'he') ? 'rtl' : ''}`}>
        <img className='footer_img' src={footer_img} />
        <div className='footer-links-section-wrap'>
          <div className='container footer-links-section d-sm-flex'>
            <div className='col-md-4 contact-section'>
              <div className='title'>
                {_.get(translate, 'contact')}
              </div>
              {_.get(translate, 'address')}<br />
              08-9940204<br />
              <a href="mailto:mazkirut@kreim.org.il">mazkirut@kreim.org.il</a>
              <div className="mt-0 gap-2 credit"> © 2023 Created by<img width="90" src={onelogo}/></div>
            </div>
            <div className='col-md-4 d-inline-grid justify-content-center align-items-center'>
              <LinktoDonate />
            </div>
            <div className='website-section col-md-4 d-inline-grid align-items-center justify-content-end'>
              <a href="https://www.reim.org.il/" target="_blank">{_.get(translate, 'website')}</a>
              <div className="mt-0  credit"> © 2023 Created by<img width="90" src={onelogo}/></div>
            </div>
          </div>
         
        </div>
      </div>
      </div>
      <div className='sticky-button d-none fixed-bottom'><LinktoDonate /></div>
    </div>
  );
}

export default App;
